//Colors
$color-main-green: #6BBD45;
$color-main-orange: #F1892A;
$color-second-orange: #FCC159;
$color-second-blue-green: #01444C;

$color-blue-green: #016761;
$color-calendar-event: #E8E8E8;
$color-grey: #767273;
$color-grey-disabled: #D4D4D4;

$color-transparent-background: rgba(52, 52, 52, 0.22);
$color-yellow: #FCC159;
$color-blue-tooltip: #01444C;

//Variable on primary color : allow to modify this var from typescript
:root {
    --color-main-primary-orange-or-green: #6BBD45;
}



