@font-face {
    font-display: swap;
    font-family: 'Inter-Light';
    src: url('/assets/fonts/Inter-Light.ttf') format('truetype');
}
      
@font-face {
    font-display: swap;
    font-family: 'Inter-Regular';
    src: url('/assets/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Inter-Bold';
    src: url('/assets/fonts/Inter-ExtraBold.ttf') format('truetype');
}

h1, h2, h3, h4, h5, h6 {
    margin: 0px !important;
    user-select: none;
}

//H1 > 24px
h1 {
    line-height: 29px;
    font-size: 24px;
}

h1.bold {
    font-family: 'Inter-Bold';
}

h1.regular {
    font-family: 'Inter-Regular';
}

//H2 > 22px
h2 {
    line-height: 26px;
    font-size: 22px;
}

h2.regular {
    font-family: 'Inter-Regular';
}

h2.bold {
    font-family: 'Inter-Bold';
}

h2.light {
    font-family: 'Inter-Light';
}

//H3 > 20px
h3 {
    line-height: 24px;
    font-size: 20px;
}

h3.regular {
    font-family: 'Inter-Regular';
}

h3.bold  {
    font-family: 'Inter-Bold';
}

h3.light  {
    font-family: 'Inter-Light';
}

//H4 > 16px
h4 {
    line-height: 19px;
    font-size: 16px;
}

h4.regular {
    font-family: 'Inter-Regular';
}

h4.bold  {
    font-family: 'Inter-Bold';
}

h4.light  {
    font-family: 'Inter-Light';
}

//H5 > 14px
h5 {
    line-height: 16px;
    font-size: 14px;
}

h5.regular {
    font-family: 'Inter-Regular';
}

h5.bold  {
    font-family: 'Inter-Bold';
}

h5.light  {
    font-family: 'Inter-Light';
}

//Calendar numbers
//H6 > 12px
h6 {
    line-height: 14px;
    font-size: 12px;
}

h6.bold {
    font-family: 'Inter-Bold';
}

h6.regular {
    font-family: 'Inter-Regular';
}

h6.light {
    font-family: 'Inter-Light';
}

//a for links
a {
    font-family: 'Inter-Light';
    line-height: 14px;
    font-size: 12px;
}
