@import './scss/fonts'; 
@import './scss/colors'; 
@import './scss/inputs'; 

/* You can add global styles to this file, and also import other style files */
html, body {
    margin: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient($color-main-green, $color-blue-green);
    overscroll-behavior-y: contain;
    box-sizing: border-box;
    //display: flex;
    /* align-items: center;
    justify-content: center; */
}

//SCROLLBAR 
::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
  
/*Appliquer des modifications sur le fond de la zone de l'ascenseur.*/
::-webkit-scrollbar-track {
    background-color: transparent;
} 
/*Cible l'ascenseur lui-même.*/
::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 100px;
}

/* Pour les écrans en mode paysage */
@media only screen and (max-width: 1000px) and (orientation: landscape) {
    body {
        
        margin: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient($color-main-green, $color-blue-green);
        overscroll-behavior-y: contain;
        box-sizing: border-box;
    }
    
    body::before {
      content: "Ce site est optimisé pour un affichage en mode portrait. Veuillez tourner votre appareil.";
    }
}



