@import './fonts'; 
@import './colors'; 

input {
    border:0;
    outline:0;
    border-radius: 5px;
}

input:focus {
    outline:none!important;
}

input[type="text"]
{   
    width: 150px;
    height: 28px;
    font-family: 'Inter-Regular';
    padding-left: 8px;
    box-sizing: border-box;
}

input[type="date"]
{   
    width: 130px;
    height: 28px;
    font-family: 'Inter-Regular';
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
}

/* Masquer l'icône native */
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    /* /* display: none;
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    pointer-events: none;
    width: 0;
    overflow: hidden; */
    /* clip-path: inset(50%);
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    position: absolute; */
}

/* input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    color: transparent;
}


input[type="date"]::-moz-focus-inner,
input[type="time"]::-moz-focus-inner {
    border: 0;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    opacity: 0;
}


input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator, 
input[type="time"]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
    opacity: 0;
} */

input[type="time"]::-webkit-calendar-picker-indicator {
    /* content: url('../assets/icons/time-clock.svg');
    width: 18px;
    height: 18px;
    background-color: white; */
    //background: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    /* width: 18px;
    height: 18px;
    content: url('../assets/icons/date-calendar.svg');
    background-color: white; */
    //background: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type="time"]
{   
    height: 28px;
    width: 80px;
    padding-left: 8px;
    font-family: 'Inter-Regular';
    box-sizing: border-box;
}

input[type="checkbox"]
{   
    border-radius: 40px; //dosn't work
    height: 15px;
    width: 15px;
    accent-color: $color-yellow;
}

input[type="checkbox"]:hover {
    accent-color: $color-yellow;
}

/* input[type="checkbox"]:disabled {
    accent-color: $color-yellow;
    background-color: red;
} */

textarea {
    width: 100%;
    height: 100%;
    min-height: 70px;
    border-radius: 6px;
    resize: none;
    font-family: 'Inter-Regular';
    padding-left: 8px;
    padding-right: 4px;
    outline: none;
    box-sizing: border-box;
}

button {
    width: 250px;
    height: 40px;
    background-color: $color-main-green;
    color: white;
    border-radius: 100px;
    border: none;
    cursor: pointer;
}

button:disabled {
    width: 250px;
    height: 40px;
    background-color: #4B9947;
    color: rgb(189, 186, 186);
    border-radius: 100px;
    border: none;
    cursor: default;
}

select {
    width: 200px;
    height: 28px;
    border-radius: 6px;
    border: none;
    background-color: $color-main-green;
    color: white;
    cursor: pointer;
    outline:none;
    padding-left: 8px;
}

select > option {
    //background-color: pink;
    padding-left: 16px;
}

/* select.custom-select {
    background-color: red;
} */

/* Optionnel: Ajouter une icône personnalisée pour la flèche */
select.custom-select::after {
    /* content: '▼';
    position: absolute;
    
    right: 10px;
    top: calc(50% - 5px);
    pointer-events: none; */
}

input select button textarea div {
    -webkit-tap-highlight-color: transparent;
}